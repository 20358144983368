<template>
  <div>
    <SiteNav @handleGet="handleGet" />

    <main class="relative">
      <div class="gradient-bg privacy-policy-gradient"></div>
      <header class="pt-16 pb-12 lg:pt-28 lg:pb-14">
        <div class="l-container">
          <h1 class="heading level-2">Privacy Policy</h1>
        </div>
      </header>

      <section class="mb-20">
        <div class="l-container">
          <Document title="Privacy Notice" numbered>
            <DocumentSection heading="Introduction">
              <p>
                WorknProsper (&quot;we&quot;, &quot;us&quot;, or
                &quot;our&quot;) is a financial technology company that provides
                digital solutions that help individuals manage their wealth. Our
                digital solutions help individuals manage payments, save money,
                access loans and investments, and automate these transactions.
              </p>
              <p>
                This Privacy Notice (“Notice”) governs your use of our website.
                We provide this Notice because you have a right to know what
                information we collect, why we collect it, how it is protected
                and used, and the circumstances under which it may be disclosed.
              </p>
            </DocumentSection>

            <DocumentSection heading="Terms of Use">
              <p>
                You are required to comply with the provisions of our Terms of
                Use in relation to the information provided.
              </p>
            </DocumentSection>

            <DocumentSection heading="The data that we process">
              <p>
                Personal data is any information about an individual that can be
                used to identify that person directly or indirectly. For
                example, while using the website, we process personal
                information from you to contact or identify you, and some
                information may be collected automatically for our website to
                function properly. We also collect personal data from
                third-party sources or through your use of our services, such as
                when you sign up or register for any of our services. We collect
                the following information:
              </p>
              <div class="border-2 border-black grid grid-cols-2">
                <div class="border-r-2 border-black">
                  <p class="border-b-2 border-black p-2 font-semibold">
                    Companies
                  </p>
                  <div class="pl-2">
                    <p>Name of Company</p>
                    <p>Registered Address</p>
                    <p>Employee Details</p>
                  </div>
                </div>
                <div class="">
                  <p class="border-b-2 border-black p-2 font-semibold">Users</p>
                  <div class="pl-2">
                    <p>Your name</p>
                    <p>Email address</p>
                    <p>Phone number</p>
                    <p>Physical Address</p>
                    <p>Location</p>
                    <p>Bank Verification Number</p>
                    <p>Bank account details</p>
                    <p>Photo ID</p>
                    <p>Valid national identity card</p>
                    <p>Usage data</p>
                    <p>Transactional data</p>
                    <p>Employment company</p>
                  </div>
                </div>
              </div>
            </DocumentSection>

            <DocumentSection heading="Cookies and tracking technologies">
              <p>
                We use cookies and other tracking technologies, and information
                about their usage is specified in our Cookies and tracking
                technologies notice.
              </p>
            </DocumentSection>

            <DocumentSection heading="Lawful bases for processing data">
              <p>
                We are required to process your data under at least one of these
                lawful bases, as specified under the relevant data protection
                laws:
              </p>
              <ul>
                <li>
                  <bold class="font-semibold"> Legitimate interest: </bold>
                  Processing your data is necessary for our legitimate interests
                  or the legitimate interests of a third party, provided the
                  legitimate interest does not outweigh the data subject’s
                  rights.
                </li>
                <li>
                  <bold class="font-semibold"> Consent: </bold>
                  You have given explicit consent for us to process your data
                  for a specific purpose.
                </li>
                <li>
                  <bold class="font-semibold"> Contract: </bold>
                  If the data processing is necessary for a contract with us or
                  because we have asked you to take specific steps before
                  entering that contract.
                </li>
                <li>
                  <bold class="font-semibold"> Legal obligation: </bold>
                  If the processing of your data is necessary where there is a
                  statutory obligation on us.
                </li>
              </ul>
            </DocumentSection>

            <DocumentSection
              heading="Purpose of Processing Your Data and the Lawful Bases"
            >
              <div class="border-2 border-black grid grid-cols-2">
                <div class="border-r-2 border-black">
                  <p class="border-b-2 border-black p-2 font-semibold">
                    Purpose of Processing
                  </p>
                  <div class="pl-2">
                    <ul>
                      <li>
                        To help us develop, improve, customise, or restructure
                        our services.
                      </li>
                      <li>
                        To inform you whenever there are changes to our terms of
                        business or services.
                      </li>
                      <li>
                        To take statistical data and analytics for our internal
                        use.
                      </li>
                      <li>
                        To analyse site usage and provide, maintain, and improve
                        the content and functionality of the website.
                      </li>
                      <li>
                        To check your creditworthiness and rating for loan
                        eligibility.
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="">
                  <p class="border-b-2 border-black p-2 font-semibold">
                    Lawful Bases
                  </p>
                  <div class="pl-2">
                    <ul>
                      <li>
                        <p>Legitimate interest</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="border-2 border-t-0 border-black grid grid-cols-2">
                <div class="border-r-2 border-black">
                  <div class="p-2">
                    <ul>
                      <li>To send marketing or promotional messages to you.</li>
                    </ul>
                  </div>
                </div>
                <div>
                  <div class="p-2">Consent</div>
                </div>
              </div>
              <div class="border-2 border-t-0 border-black grid grid-cols-2">
                <div class="border-r-2 border-black">
                  <div class="p-2">
                    <ul>
                      <li>To secure your data and prevent fraud.</li>
                    </ul>
                  </div>
                </div>
                <div>
                  <div class="p-2">Legitimate interest, legal obligation</div>
                </div>
              </div>
              <div class="border-2 border-t-0 border-black grid grid-cols-2">
                <div class="border-r-2 border-black">
                  <div class="p-2">
                    <ul>
                      <li>To send you service-related messages.</li>
                      <li>To manage and create your account.</li>
                      <li>
                        To administer our services through any of our products
                        you opt to use.
                      </li>
                      <li>
                        To enforce our terms of service and any terms and
                        conditions of any other agreements for our services.
                      </li>
                      <li>To communicate with you and ensure user support.</li>
                      <li>
                        To address your inquiries, process registration, and
                        completion of financial transactions.
                      </li>
                    </ul>
                  </div>
                </div>
                <div>
                  <div class="p-2">Contract</div>
                </div>
              </div>
              <div class="border-2 border-t-0 border-black grid grid-cols-2">
                <div class="border-r-2 border-black">
                  <div class="p-2">
                    <ul>
                      <li>
                        To interact with regulatory authorities or other public
                        authorities concerning you.
                      </li>
                      <li>
                        For identity verification and to comply with anti-money
                        laundering (AML) and know your customer (KYC)
                        regulations.
                      </li>
                    </ul>
                  </div>
                </div>
                <div>
                  <div class="p-2">Legal obligation</div>
                </div>
              </div>
            </DocumentSection>

            <DocumentSection heading="Your Rights as a Data Subject">
              <p>
                You are vested with certain rights as a data subject. They
                include the right to:
              </p>
              <ul>
                <li>
                  access personal data we hold about you by requesting a copy of
                  the personal data we hold about you;
                </li>
                <li>
                  rectify such information where you believe it to be
                  inaccurate;
                </li>
                <li>
                  restrict the processing of your data in certain circumstances;
                </li>
                <li>
                  object to the processing of your data where we intend to
                  process such data for marketing purposes;
                </li>
                <li>
                  where feasible, receive all personal data you have provided to
                  us—in a structured, commonly used, and machine-readable
                  format—and transmit the information to another data
                  controller;
                </li>
                <li>
                  request the erasure of your data (also known as the right to
                  be forgotten);
                </li>
                <li>withdraw your consent to processing your data;</li>
                <li>
                  not be subjected to a decision based solely on automated
                  processing or profiling, and
                </li>
                <li>
                  lodge a complaint with the regulatory authority where you have
                  reason to believe that we have violated the terms of this
                  Notice. (You may complain or seek redress from us within 30
                  days from when you first detected the alleged violation.)
                </li>
              </ul>
              <p>
                You may seek to exercise any of the above rights at any time by
                emailing us at
                <a
                  href="http://wnp@myeazipay.ng"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="text-blue-600 underline"
                  >wnp@myeazipay.ng</a
                >
              </p>
            </DocumentSection>

            <DocumentSection heading="Who do we disclose data to?">
              <p>
                We may share your data with third parties, depending on the
                product you wish to use. To find out who we share your data with
                and why, please read the privacy notice for that specific
                product. When using our website, we share your data with the
                following third parties:
              </p>
              <table class="border-2 border-black w-full mb-1">
                <thead>
                  <tr class="text-left uppercase">
                    <th   class="py-2 w-4/12 px-2 border-b-2 border-r-2 border-black">
                      Third Parties
                    </th>
                    <th class="py-2 w-8/12 px-2 border-b-2 border-black">
                      Purpose of data sharing
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border-r-2 border-black pl-2">Google</td>
                    <td>
                      <div class="pl-2">
                        <p>
                          We use Google Tag Manager to manage all user tracking
                          codes centrally.
                        </p>
                        <p>
                          We also use Google Analytics to identify how you
                          interact with our website.
                        </p>
                        <p>
                          Read Google’s privacy notice
                          <a
                            href="https://policies.google.com/privacy?hl=en"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="text-blue-600 underline"
                            >here.</a
                          >
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr class="border-t-2 border-black">
                    <td class="border-r-2 border-black pl-2">
                      Payment Processors
                    </td>
                    <td>
                      <div class="pl-2">
                        For processing financial transactions and managing
                        payments, we may need to share data with third-party
                        payment processors or financial institutions.
                      </div>
                    </td>
                  </tr>
                  <tr class="border-t-2 border-black">
                    <td class="border-r-2 border-black pl-2">
                      <p>Investment Vendors</p>
                    </td>
                    <td>
                      <div class="pl-2">
                        <p>
                          We may share relevant data to facilitate seamless
                          investment services and opportunities.
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr class="border-t-2 border-black">
                    <td class="border-r-2 border-black pl-2">
                      <p>Financial Institution(s)</p>
                    </td>
                    <td>
                      <div class="pl-2">
                        <p>
                          We collaborate with various financial institutions to
                          provide our services. As part of this collaboration,
                          we collect and share your payment and transactional
                          data with your financial institution for essential
                          functions such as payment processing, fraud
                          prevention, credit risk reduction and other related
                          financial services.
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr class="border-t-2 border-black">
                    <td class="border-r-2 border-black pl-2">
                      <p>Acquiring companies</p>
                    </td>
                    <td>
                      <div class="pl-2">
                        <p>
                          We may share your data during the negotiation process
                          for the sales, merger or acquisitions of any of our
                          digital products or assets.
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr class="border-t-2 border-black">
                    <td class="border-r-2 border-black pl-2">
                      <p>Legal and Regulatory Authority</p>
                    </td>
                    <td>
                      <div class="pl-2">
                        <p>
                          We may disclose your personal information if we
                          believe it is reasonably necessary to comply with a
                          law, regulation, order, subpoena, audit, or to protect
                          any person&#39;s safety or to address fraud, security,
                          or technical issues.
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr class="border-t-2 border-black">
                    <td class="border-r-2 border-black pl-2">
                      <p>Credit Bureau</p>
                    </td>
                    <td>
                      <div class="pl-2">
                        <p>
                          When assessing creditworthiness for loan applications,
                          we may share relevant data with credit bureaus or
                          credit reporting agencies.
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr class="border-t-2 border-black">
                    <td class="border-r-2 border-black pl-2">
                    <p>Service Providers</p>

                    </td>
                    <td>
                      <div class="pl-2">
                        <p>
                          We will share your personal data with service providers in
                          order for them to provide services to us, such as payment
                          processing service providers, to conduct data processing
                          on our behalf, or for identity verification,
                          centralisation, or logistics purposes.
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

          
            </DocumentSection>

            <DocumentSection heading="Retention of your data">
              <p>
                The personal data we process will be stored for as long as
                necessary to fulfil the purposes described in this Notice.
                However, we will also retain data subject to relevant provisions
                of applicable laws, resolve disputes, prevent fraud and abuse,
                and enforce our legal agreements and policies. In addition, we
                delete your data for targeted marketing purposes once you
                unsubscribe from our marketing communications.
              </p>
              <p>
                Please note that your data may be retained for a longer period,
                notwithstanding your request to remove it, where there is a
                legal requirement to do so.
              </p>
            </DocumentSection>
            <DocumentSection heading="Security of data">
              <p>
                We prioritise your privacy and use advanced measures to protect
                your data. While no online platform is entirely foolproof, we
                are dedicated to safeguarding your information against loss,
                misuse, and unauthorised access. If a significant breach occurs,
                we will promptly inform you and act swiftly to address it.
              </p>
            </DocumentSection>

            <DocumentSection heading="International transfer of data">
              <p>
                We transfer personal data outside our country of operation. We
                ensure any cross-border data transfers adhere to all necessary
                data protection regulations. This means that before transferring
                personal data, we either confirm that the recipient country has
                robust data protection laws or, if not, employ specific
                contractual terms and other appropriate safeguards to protect
                the data. In cases where the destination country might not meet
                stringent data protection standards, we will leverage the
                relevant data transfer mechanism, seek authorisation from the
                regulator, or obtain your consent before proceeding and inform
                you of any risks. Should you wish to learn more about how we
                ensure data protection during these transfers, details will be
                provided upon request.
              </p>
            </DocumentSection>

            <DocumentSection heading="Marketing and communications">
              <p>
                We only send marketing communications to you with your consent.
                You can opt-out of our marketing or object to further processing
                by clicking on the &#39;unsubscribe&#39; button at the bottom of
                the page.
              </p>
            </DocumentSection>

            <DocumentSection heading="Complaints">
              <p>
                If you have any inquiries or complaints, please contact our Data
                Protection Officer (DPO) at

                <a
                  href="http://wnp@myeazipay.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="text-blue-600 underline"
                  >wnp@myeazipay.com</a
                >
                Our DPO will examine your concerns and update you on the
                resolution process.
              </p>
            </DocumentSection>

            <DocumentSection heading="Changes to this Notice">
              <p>
                We update our privacy notice occasionally. We will notify our
                users when we make a change, and visitors will know this by
                checking the last date of the update on this page whenever they
                visit.
              </p>
            </DocumentSection>

            <DocumentSection heading="Contact Us">
              <p>
                If you have any questions relating to this Notice, your rights
                under this Notice, or are not satisfied with how we manage your
                personal data, kindly reach out to our Data Protection Officer
                at
                <a
                  href="http://wnp@myeazipay.ng"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="text-blue-600 underline"
                  >wnp@myeazipay.ng</a
                >
                or
                <a
                  href="mailto:fayokemi@myeazipay.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="text-blue-600 underline"
                  >fayokemi@myeazipay.com</a
                >
              </p>
            </DocumentSection>
          </Document>
        </div>
      </section>
    </main>
    <Modal v-if="isOpen" @close="isOpen = false">
      <div class="">
        <img src="./assets/qrcodeIos.svg" class="w-52 h-52" />

        <p class="text-center text-white">Scan to Download</p>
      </div>
    </Modal>
    <SiteFooter worknprosper />
  </div>
</template>

<script setup>
import SiteNav from "../../components/wnpSiteNav.vue";
import Modal from "./components/Modal.vue";
import SiteFooter from "../../components/SiteFooter.vue";
import DocumentSection from "../../components/documents/DocumentSection.vue";
import Document from "../../components/documents/Document.vue";
import { ref } from "vue";
const isOpen = ref(false);

const handleGet = () => {
  isOpen.value = true;
};
</script>

<style lang="scss" scoped src="../../styles/www.scss"></style>
<style lang="scss" scoped src="../../styles/doc.scss"></style>
